import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "shared/auth";
import { useQuery } from "react-query";
import HTMLFlipBook from "react-pageflip";
import { fetchEbook } from "shared/react-query/strapiAPI";
import { getStrapiAssetURL } from "shared/utils/strapiUtils";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/styles.css";

// Function to sort images by the number in the filename
function sortByNumberInFilename(objects) {
  return objects.sort((a, b) => {
    const numA = parseInt(a.url.match(/_(\d+)_/)[1], 10);
    const numB = parseInt(b.url.match(/_(\d+)_/)[1], 10);
    return numA - numB;
  });
}


function BookPageViewer(props) {
  const { auth } = useAuth();

  const { data, error, isLoading } = useQuery(
    ["ebook", auth.token],
    () => fetchEbook(),
    {
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    }
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading pages</div>;
  }

  const sortedPages = sortByNumberInFilename(data?.Pages ?? []);


  return (
    <>
      {props.children}
      <Lightbox
        open={props.open}
        zoom={{
          maxZoom:10
        }}
        close={() => props.setOpen(false)}
        plugins={[Zoom]}
        slides={sortedPages.map((page, index) => (
          { src: getStrapiAssetURL(page.url) }
        ))
        }
      />
    </>
  );
}

export default BookPageViewer;
